import * as React from "react"
import { Link, withPrefix } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Router } from "@reach/router"

import Layout from "../components/layout"
import Seo from "../components/seo"
import FinalPage from "../components/MyPage/FinalPage"
import Home from "../components/MyPage/Home"
import UserInfo from "../components/MyPage/UserInfo"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest } from "../components/Security/authConfig"
import { MsalAuthenticationTemplate } from "@azure/msal-react"
import { ErrorComponent } from "../components/Utils/ErrorComponent"
import LoadingModal from "../components/Modal/LoadingModal"

const IndexPage = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingModal}
    >
      <UserInfo />
    </MsalAuthenticationTemplate>
  )
}

export default IndexPage
