import React, { useEffect, useState } from 'react';
import { Checkmark } from '../Graphics/Checkmark';


export type CheckboxType = {
    defaultChecked?: boolean,
    disabled?: boolean,
    checked?: boolean,
    onClick?:(state:boolean)=>void,
    onChange?:()=>void,
}

const Checkbox = (props:CheckboxType) => {
  const [checked, setChecked] = useState<boolean>(props.defaultChecked || false)

  useEffect(()=>{
    if(props.checked !== undefined){
      setChecked(props.checked)
    }
  },[props.checked])

  const onClick = () => {
    if(props.checked === undefined){
      if(props.onClick !== undefined) props.onClick(!checked)
      setChecked(!checked)
    }
    else{
      if(props.onChange !== undefined) props.onChange()
    }
  }
  return(
    <>
      <button
      className="h-5 w-5"
      type="button"
      onClick={onClick}>
        {checked ? <>
          <div className="bg-blue w-5 h-5 border-2 border-blue flex justify-center items-center">
            <Checkmark strokeWidth={1} className="w-2 h-2"/>
            {/* <div className="w-3 h-3"></div> */}
            

          </div>  
        </>:<>
          <div className="w-5 h-5 border-2 border-blue border-checkbox-border flex justify-center items-center">
            <div className="w-3 h-3"></div>
          </div>
        </>}
      </button>
    </>
  )

}

export default Checkbox