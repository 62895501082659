import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { authToken } from "../Security/authToken"
import { patchApi } from "../Utils/WebApi-utils"
import moment from "moment"
import { showMoreButton } from "../Styling/Buttons"
import LoadingModal from "../Modal/LoadingModal"
import { navigate } from "gatsby"

const SeePosts = ({ posts }) => {
  const [size, setSize] = useState(2)
  return (
    <div>
      <div className="pb-6">
        {posts?.map((item, i) => (
          <div key={i}>
            <div className="flex flex-col py-2 px-4 mt-4 bg-white" key={i}>
              <label className="font-sans text-blue mb-2 font-bold">
                {item?.role?.roleName}
              </label>
              <label className="font-sans text-blue">
                {item?.committee?.committeeName}
              </label>
              {item?.toDate !== undefined ? (
                <label className="font-sans text-blue">
                  Periode:{" "}
                  <label className="font-bold">
                    {moment(item?.fromDate).format("DD.MM.YYYY")} -{" "}
                    {moment(item?.toDate).format("DD.MM.YYYY")}
                  </label>
                </label>
              ) : (
                <label className="font-sans text-blue">
                  Siden{" "}
                  <label className="font-bold">
                    {moment(item?.fromDate).format("DD.MM.YYYY")}
                  </label>
                </label>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const MyPosts = params => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeActivePosts, setSeeActivePosts] = useState(false)
  const [seeInactivePosts, setSeeInactivePosts] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const queryClient = useQueryClient()
  const [myActivePosts, setMyActivePosts] = useState([])
  const [myInactivePosts, setMyInactivePosts] = useState([])

  const GetMyPosts = (account, inProgress, instance) =>
    useQuery(
      "getMyPosts",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefCommittee/me", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => {
            const tempActive = res.data?.filter(item => item.statusCode === 1)
            setMyActivePosts(tempActive)
            const tempInactive = res.data?.filter(item => item.statusCode === 2)
            setMyInactivePosts(tempInactive)
          })
      },
      {
        enabled: seeActivePosts,
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const MyPosts1 = GetMyPosts(accounts, inProgress, instance)

  useEffect(() => {
    if (seeActivePosts != false) {
      setSeeActivePosts(true)
    }
  }, [seeActivePosts])

  return (
    <div>
      {MyPosts1.isLoading ? (
        <LoadingModal />
      ) : (
        <div>
          <div
            className={
              showMoreButton +
              "flex justify-between border-blue items-center py-3"
            }
            onClick={() => {
              setSeeActivePosts(!seeActivePosts)
              setSeeInactivePosts(false)
            }}
          >
            {seeActivePosts ? (
              <span className="text-base font-sans font-semibold">
                Dine verv og roller
              </span>
            ) : (
              <span className="text-base font-sans">Dine verv og roller</span>
            )}
            {seeActivePosts ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            )}
          </div>
          {seeActivePosts ? (
            <div>
              {loadingError ? (
                <div>
                  <p className="font-sans">
                    Det har skjedd en feil med dine verv og roller, prøv igjen
                    senere.
                  </p>
                </div>
              ) : (
                <div>
                  {myActivePosts.length > 0 ? (
                    <SeePosts posts={myActivePosts} />
                  ) : (
                    <label className="font-sans mb-2">
                      Du har <label className="font-bold">ingen</label> aktive
                      verv og roller
                    </label>
                  )}

                  <div className="mt-2">
                    <button
                      className="font-sans text-blue hover:text-orange-dark hover:cursor-pointer pb-4"
                      onClick={() => setSeeInactivePosts(!seeInactivePosts)}
                    >
                      {seeInactivePosts ? (
                        <div className="flex flex-row items-center justify-items-center">
                          <label className="border-b-2 font-semibold hover:cursor-pointer">
                            Tidligere verv
                          </label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className="flex flex-row items-center justify-items-center">
                          <label className="border-b hover:cursor-pointer">
                            Tidligere verv
                          </label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 text-xl font-serif transition-all"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                      )}
                    </button>
                  </div>
                  {seeInactivePosts ? (
                    <div className="mb-2">
                      {myInactivePosts.length > 0 ? (
                        <SeePosts posts={myInactivePosts} />
                      ) : (
                        <label className="font-sans mb-2">
                          Du har <label className="font-bold">ingen</label>{" "}
                          tidligere verv og roller
                        </label>
                      )}
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          ) : null}
          {seeActivePosts ? (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
          ) : (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
          )}
        </div>
      )}
    </div>
  )
}

export default MyPosts
