import React, { Component, useCallback, useEffect, useState } from "react"
import { MyConsents, UpdateConsent } from "./MyConsents"
import MyMarketing from "./MyMarketing"
import { useMutation, useQueryClient } from "react-query"
import { patchApi } from "../Utils/WebApi-utils"
import { useMsal } from "@azure/msal-react"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useForm } from "react-hook-form"
import { GetProfile } from "../Utils/ReactQueries"
import {
  underlineButton,
  down,
  saveButton,
  showMoreButton,
} from "../Styling/Buttons"
import LoadingModal from "../Modal/LoadingModal"

export type Consents = {
  doNotEmail: boolean
  doNotBulkPostalMail: boolean
  doNotSms: boolean
  doNotPhone: boolean
}

const MyComAndMarketing = params => {
  const [consents, setConsents] = useState<Consents | any>()

  const [addMemberList, setAddMemberList] = useState<any>([])
  const [removeMemberList, setRemoveMemberList] = useState<any>([])
  const [exisitingMarketingList, setExisitingMarketingList] = useState([])
  const { instance, accounts, inProgress } = useMsal()
  const [, updateState] = useState<any>()
  const queryClient = useQueryClient()
  const { register, handleSubmit, setValue, reset } = useForm()
  const [seeConsents, setSeeConsents] = useState(false)
  const [resetComAnConsents, setResetComAnConsents] = useState(false)
  const userProfile = GetProfile(accounts, inProgress, instance)

  const UpdateMyMarketingLists = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefSubscriptionLists/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success("Endringene på abonnementlister ble lagret")
        queryClient.invalidateQueries(["getMyMarketinglist"])
        queryClient.invalidateQueries(["getMarketinglist"])
      },
      onError: data => {
        toast.error("Noe gikk galt med abonnementlisten!")
      },
    }
  )

  useEffect(() => {
    if (resetComAnConsents) {
      setSeeConsents(true)
      setResetComAnConsents(false)
    }
  }, [resetComAnConsents])

  const onReset = () => {
    setSeeConsents(false)
    setResetComAnConsents(true)
  }

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success("Endringene i samtykke ble lagret")
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Noe gikk galt med samtykke!")
      },
    }
  )

  let patchObj: any = {
    AddMemberList: [],
    RemoveMemberList: [],
  }
  const onSubmit = () => {
    patchObj = {
      AddMemberList: addMemberList,
      RemoveMemberList: removeMemberList,
    }
    if (consents != undefined || consents != null) {
      UpdateUser.mutate(consents)
    }
    if (
      patchObj.AddMemberList.length > 0 ||
      patchObj.RemoveMemberList.length > 0
    ) {
      UpdateMyMarketingLists.mutate(patchObj)
    }

    setAddMemberList([])
    setRemoveMemberList([])
    setConsents(null)
  }

  let prevConsents: Consents = {
    doNotEmail: userProfile.data?.doNotEmail,
    doNotBulkPostalMail: userProfile.data?.doNotBulkPostalMail,
    doNotSms: userProfile.data?.doNotSms,
    doNotPhone: userProfile.data?.doNotPhone,
  }

  return (
    <div>
      {UpdateUser.isLoading || UpdateMyMarketingLists.isLoading ? (
        <LoadingModal />
      ) : null}
      <div
        className={
          showMoreButton + "flex justify-between border-blue items-center py-3"
        }
        onClick={() => setSeeConsents(!seeConsents)}
      >
        {seeConsents ? (
          <span className="text-base font-sans font-semibold">
            Kommunikasjon og nyhetsbrev
          </span>
        ) : (
          <span className="text-base font-sans">
            Kommunikasjon og nyhetsbrev
          </span>
        )}
        {seeConsents ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        )}
      </div>
      {seeConsents ? (
        <form name="contact" method="patch" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <UpdateConsent
              setConsents={setConsents}
              prevConsents={prevConsents}
              consents={consents}
            />
            <MyMarketing
              setAddMemberList={setAddMemberList}
              setRemoveMemberList={setRemoveMemberList}
              setExisitingMarketingList={setExisitingMarketingList}
              addMemberList={addMemberList}
              exisitingMarketingList={exisitingMarketingList}
              removeMemberList={removeMemberList}
            />
            <button
              type="submit"
              className={saveButton + down}
              onClick={() => {}}
            >
              Lagre endringer
            </button>
            <button type="reset" className={underlineButton} onClick={onReset}>
              Forkast endringer
            </button>
          </div>
        </form>
      ) : null}
      {seeConsents ? (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
      ) : (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
      )}
    </div>
  )
}
export default MyComAndMarketing
