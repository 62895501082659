import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import { authToken } from "../Security/authToken"
import { down, saveButton, showMoreButton } from "../Styling/Buttons"
import { postApi } from "../Utils/WebApi-utils"
import download from "downloadjs"
import moment from "moment"
import LoadingModal from "../Modal/LoadingModal"
import { navigate } from "gatsby"

type RequestObject = {
  subject: string
  documentRequest: string
  fileName: string
}
export const MyDocuments = () => {
  const [seeDocuments, setSeeDocuments] = useState(false)
  const { instance, accounts, inProgress } = useMsal()
  const [loadingError, setLoadingError] = useState(false)
  const [createdDocument, setCreatedDocument] = useState(false)
  const { handleSubmit } = useForm()
  const queryClient = useQueryClient()

  const GetDocuments = (account, inProgress, instance) =>
    useQuery(
      "getDocuements",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefAttachment/Documents", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeDocuments,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const MyDocuments = GetDocuments(accounts, inProgress, instance)

  // console.log(MyDocuments)

  const downloadDocument = async fileName => {
    //await new Promise(resolve => setTimeout(resolve, 500))
    return axios
      .get(
        process.env.GATSBY_APP_WEBAPI +
          "HefAttachment/DownloadDocument?fileName=" +
          fileName,
        {
          headers: {
            "Conent-Type": "application/pdf",
            "Ocp-Apim-Subscription-Key":
              process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
            Authorization:
              "Bearer " + (await authToken(accounts, inProgress, instance)),
          },
          responseType: "blob",
        }
      )
      .then(res => {
        download(res.data, fileName)
      })
      .catch(e => toast.error(e))
  }

  const RequestDocument = useMutation(
    values =>
      postApi(
        process.env.GATSBY_APP_WEBAPI + "HefAttachment/DocumentRequest",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        toast.success(
          "Dokumentet blir nå generert, dette kan ta en liten stund. Åpne dine dokumenter på nytt for å se dokumentet."
        )
        // console.log(data)
      },
      onError: data => {
        toast.error("Noe gikk galt med dokumentet!")
      },
    }
  )

  const onGdprRequest = (subject, docReq) => {
    let reqObj: any = {
      subject: subject,
      documentRequest: docReq,
      fileName: subject,
    }

    RequestDocument.mutate(reqObj)
    setCreatedDocument(true)
    //console.log(requestObject)
  }

  const GetPdf = async fileName => {
    //await new Promise(resolve => setTimeout(resolve, 500))
    return axios
      .get(
        process.env.GATSBY_APP_WEBAPI +
          "HefAttachment/DownloadDocument?fileName=" +
          fileName,
        {
          headers: {
            "Conent-Type": "application/pdf",
            Authorization:
              "Bearer " + (await authToken(accounts, inProgress, instance)),
          },
          responseType: "blob",
        }
      )
      .then(res => {
        download(res.data, fileName)
      })
  }

  // const myDocuments = GetDocuments(account, inProgress, instance)

  return (
    <div>
      {MyDocuments.isLoading ? (
        <LoadingModal />
      ) : (
        <div>
          <div
            className={
              showMoreButton +
              "flex justify-between border-blue items-center py-3"
            }
            onClick={() => setSeeDocuments(!seeDocuments)}
          >
            {seeDocuments ? (
              <span className="text-base font-sans font-semibold">
                Dine dokumenter
              </span>
            ) : (
              <span className="text-base font-sans">Dine dokumenter</span>
            )}
            {seeDocuments ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            )}
          </div>
          {seeDocuments ? (
            <div>
              {loadingError ? (
                <div>
                  <p className="font-sans">
                    Det har skjedd en feil med dine dokumenter, prøv igjen
                    senere.
                  </p>
                </div>
              ) : (
                <div>
                  {MyDocuments?.data?.length > 0 ? (
                    <div>
                      {MyDocuments?.data?.map((item, i) => (
                        // Active documents
                        <div key={i}>
                          <div className="bg-white font-sans text-blue px-4 py-4 flex flex-col mb-4">
                            <div className="flex flex-col">
                              <label className=" font-bold font-sans text-blue">
                                Utskrift av dine data
                              </label>
                              <label className="font-sans text-blue">
                                Lagret siden
                                <label className="font-bold">
                                  {moment(item.createdOn).format(
                                    " " + "DD.MM.YYYY"
                                  )}
                                </label>
                              </label>
                              <label className="font-sans text-blue">
                                Slettes
                                <label className="font-bold">
                                  {moment(item.createdOn)
                                    .add(7, "d")
                                    .format(" " + "DD.MM.YYYY")}
                                </label>
                              </label>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  downloadDocument(
                                    process.env.GATSBY_APP_GDPR_REPORT_FILENAME
                                  )
                                }
                                className={saveButton + down + " mt-2 "}
                              >
                                Last ned
                              </button>
                              <button
                                className={saveButton + down + " mt-2 "}
                                onClick={() =>
                                  onGdprRequest(
                                    process.env.GATSBY_APP_GDPR_REPORT_SUBJECT,
                                    process.env
                                      .GATSBY_APP_GDPR_REPORT_DOCUMENT_REQUEST
                                  )
                                }
                              >
                                Oppdater
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bg-gray font-sans flex flex-col mb-4">
                      <div>
                        <label className="">
                          {createdDocument
                            ? "Dokumentet blir nå generert. Åpne dine dokumenter igjen om en liten stund"
                            : "Du har ingen dokumenter"}
                        </label>
                      </div>
                      {createdDocument ? null : (
                        <div>
                          <div className="mt-4">
                            <label className="font-bold text-blue">
                              Få innsyn i hva vi vet om deg
                            </label>
                          </div>
                          <div>
                            <button
                              className={saveButton + down + " mt-2 "}
                              onClick={() =>
                                onGdprRequest(
                                  process.env.GATSBY_APP_GDPR_REPORT_SUBJECT,
                                  process.env
                                    .GATSBY_APP_GDPR_REPORT_DOCUMENT_REQUEST
                                )
                              }
                            >
                              Last ned dataene dine
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
          {seeDocuments ? (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
          ) : (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
          )}
        </div>
      )}
    </div>
  )
}
