import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { authToken } from "../Security/authToken"
import moment from "moment"
import { Link, navigate } from "gatsby"
import { GetProfile } from "../Utils/ReactQueries"
import LoadingModal from "../Modal/LoadingModal"
import { linkNormal } from "../Styling/Links"

/**
 * This component shows all activities connected to the user, both ceremonies and courses.
 *
 * @version 1.0.1
 * @visibleName Activity
 * @author [Dylan Lesperance]
 * @author [Henrik Lie]
 */

const MyActivities = () => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeActiveActivities, setSeeActiveActivities] = useState(false)
  const [seeInactiveActivities, setSeeInactiveActivities] = useState(false)
  const [seeSsnExist, setSeeSsnExist] = useState(false)
  const userProfile = GetProfile(accounts, inProgress, instance)
  const ssn = userProfile.data?.socialSecurityNumber

  const GetActivities = (account, inProgress, instance) =>
    useQuery(
      "myActivities",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefCeremonies/me?ssn=" + ssn, {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeSsnExist,
        retry: 3,
      }
    )

  useEffect(() => {
    if (userProfile.data?.socialSecurityNumber != undefined) {
      setSeeSsnExist(true)
    }
  }, [userProfile])

  const MyActivities = GetActivities(accounts, inProgress, instance)
  const CeremonyType = new Map([
    [0, "Konfirmasjon"],
    [1, "Navnefest"],
    [2, "Begravelse"],
    [3, "Vigsel"],
  ])

  return (
    <div className="space-y-4">
      <div>
        {MyActivities.status !== "error" ? (
          <div>
            <div className="mb-4">
              {MyActivities.isLoading ? (
                <div className="animate-bounce animate-pulse">
                  <span className="font-serif font-semibold text-lg text-blue pb-4 ">
                    Laster aktiviteter
                  </span>
                </div>
              ) : userProfile?.data?.socialSecurityNumber != undefined &&
                MyActivities?.data?.length > 0 ? (
                <span className="font-serif font-semibold text-lg text-blue pb-4">
                  Kommende aktiviteter
                </span>
              ) : (
                <span className="font-serif font-semibold text-lg text-blue pb-4">
                  Du har ingen kommende aktiviteter
                </span>
              )}
            </div>
            <div className="space-y-2 border-t-2 border-blue flex flex-col py-2">
              {/* Ceremonies */}
              {MyActivities?.data?.map((items, i) => (
                <div key={i} className="flex flex-col">
                  {items?.ceremonies !== undefined ? (
                    <Link
                      to={`/activities?${
                        "o=" +
                        items?.orderId +
                        "&p=" +
                        items?.personId +
                        "&type=ceremonies"
                      }`}
                    >
                      <div
                        className={
                          linkNormal +
                          "space-x-2.5 items-start justify-start border-b-2 border-blue pb-2 transition-all grid grid-cols-2 gap-4 text-base"
                        }
                      >
                        <div className="col-start-1 col-span-1">
                          {items?.ceremonies?.dateAndTime !== undefined ? (
                            <span className="font-serif text-base">
                              {moment(items?.ceremonies?.dateAndTime).format(
                                "DD.MM"
                              )}
                            </span>
                          ) : (
                            <span className="font-serif text-base">
                              {items?.ceremonies?.dateAndTimeDisplay
                                .charAt(0)
                                .toUpperCase() +
                                items?.ceremonies?.dateAndTimeDisplay.slice(1)}
                            </span>
                          )}
                        </div>
                        <div className="col-start-2 col-span-1 flex flex-col text-sm">
                          <span className="font-sans font-bold text-base">
                            Seremoni
                          </span>
                          <span className="font-sans ">
                            {CeremonyType.get(items?.ceremonies?.type)} i{" "}
                            {items?.ceremonies?.location}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              ))}
              {/* Courses */}
              {MyActivities?.data?.map((items, i) => (
                <div key={i} className="flex flex-col">
                  {items?.courses !== undefined ? (
                    <Link
                      to={`/activities?${
                        "o=" +
                        items?.orderId +
                        "&p=" +
                        items?.personId +
                        "&type=courses"
                      }`}
                    >
                      <div
                        className={
                          linkNormal +
                          "space-x-2.5 items-start justify-start border-b-2 border-blue pb-2 transition-all grid grid-cols-2 gap-4"
                        }
                      >
                        <div className="col-start-1 col-span-1">
                          {items?.courses?.startDate !== undefined ? (
                            <span className="font-serif text-base">
                              {moment(items?.courses?.startDate).format(
                                "DD.MM"
                              )}
                              -{moment(items?.courses?.endDate).format("DD.MM")}
                            </span>
                          ) : (
                            <span className="font-serif text-base">
                              {items?.courses?.startDateDisplay
                                .charAt(0)
                                .toUpperCase() +
                                items?.courses?.startDateDisplay.slice(1)}
                            </span>
                          )}
                        </div>
                        <div className="col-start-2 col-span-1 flex flex-col text-sm">
                          <span className="font-sans font-bold text-base">
                            {items?.courses?.category}
                          </span>
                          <span className="font-sans ">
                            i {items?.courses?.location}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="mb-4 pb-4 border-b-2 border-blue">
            <span className="font-serif font-semibold text-lg text-blue pb-4">
              Du har ingen kommende aktiviteter
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default MyActivities
