import React, { Fragment, useRef, useState, useEffect } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/outline"
import { GetProfile } from "../Utils/ReactQueries"
import { useMsal } from "@azure/msal-react"
import { useMutation, useQueryClient } from "react-query"
import { patchApi } from "../Utils/WebApi-utils"
import { Controller, useForm } from "react-hook-form"
import {
  inputFieldErrorModal,
  inputFieldModal,
  inputFieldPhoneNumber,
  inputFieldPhoneNumberError,
} from "../Styling/InputField"
import { down, right, saveButton } from "../Styling/Buttons"
import { toast } from "react-toastify"
import PhoneInput from "react-phone-input-2"
import { validatePhoneMiniUser } from "../Utils/Utils"
import { CountriesToValidate } from "../Utils/CountriesToValidate"
import { EventMessageUtils } from "@azure/msal-browser"

/**
 * This component shows up on the screen if the user have not saved/updated its personal information in 6 months or more.
 *
 * @version 1.0.1
 * @visibleName Confirm
 * @author [Dylan Lesperance]
 * @author [Henrik Lie]
 */

export const ConfirmUserInfo = ({ setInfoConfirmed }) => {
  const [open, setOpen] = useState(true)
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const inputRef = useRef({})
  const [validPhone, setValidPhone] = useState(false)
  const queryClient = useQueryClient()
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onChange",
  })
  const [isEditMode, setIsEditMode] = useState(false)
  const [phoneValue, setPhoneValue] = useState(userProfile?.data?.mobilePhone)
  const [checkRequired, setCheckRequired] = useState(true)

  // Checks the date today in iso string format
  const cancelButtonRef = useRef(null)
  const tzOffset = new Date().getTimezoneOffset() * 60000
  const localTime = new Date(Date.now() - tzOffset).toISOString()
  const today = localTime

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        queryClient.invalidateQueries(["profile"])
      },
    }
  )

  // Validation on email, phone and updates the modifiedByCustomer field.
  const onSubmit = values => {
    values.mobilePhone = phoneValue
    console.log(values)

    if (
      (values.mobilePhone == undefined || values.mobilePhone == "") &&
      (values.email == undefined || values.email == "")
    ) {
      setValidPhone(false)
      setError("email", {
        message: "Ugyldig nummer",
      })
      setError("mobilePhone", {
        message: "Ugyldig nummer",
      })

      return toast.error("Skriv inn mobilnummer eller e-post")
    }
    if (validPhone === false) {
      return toast.error("Mobilnummer er ugyldig")
    }

    values.genderCode = parseInt(values.genderCode)

    if (values.mobilePhone == undefined || values.mobilePhone.length < 3) {
      values.mobilePhone = ""
    } else if (values.mobilePhone.length == 11) {
      values.mobilePhone = values.mobilePhone
    } else {
      values.mobilePhone = "+" + values.mobilePhone
    }
    if (values.mobilePhone) {
    }
    values.modifiedByCustomer = today
    UpdateUser.mutate(values)

    setInfoConfirmed(true)
    setOpen(false)
  }

  useEffect(() => {
    if (
      userProfile.data?.email?.length == 0 &&
      userProfile.data?.mobilePhone?.length == 0
    ) {
      setCheckRequired(true)
      console.log("Test")
    } else if (validPhone || userProfile.data?.email?.length > 0) {
      setCheckRequired(false)
    }
  }, [inputRef.current["email"]])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center backdrop-blur sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-gray border-blue border-2 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="text-start">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-medium text-blue"
                  >
                    Er følgende informasjon korrekt?
                  </Dialog.Title>
                  <form
                    name="contact"
                    method="patch"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="mt-2 flex flex-col space-y-6">
                      {/* First name */}
                      <div className="flex flex-col items-start space-y-2">
                        <label className="text-sm text-blue font-sans">
                          Fornavn
                        </label>
                        <input
                          type="string"
                          className={inputFieldModal}
                          placeholder="Fornavn"
                          defaultValue={userProfile.data?.firstName}
                          {...register("firstName")}
                        ></input>
                      </div>
                      {/* Last name */}
                      <div className="flex flex-col items-start space-y-2">
                        <label className="text-sm text-blue font-sans">
                          Etternavn
                        </label>
                        <input
                          type="string"
                          className={
                            errors.lastName
                              ? inputFieldErrorModal
                              : inputFieldModal
                          }
                          placeholder="Etternavn"
                          defaultValue={userProfile.data?.lastName}
                          {...register("lastName", { required: true })}
                          //onChange={e => (patchObj.lastName = e.target.value)}
                        ></input>
                      </div>
                      {/* Email */}
                      <div>
                        <label className="text-sm text-blue font-sans">
                          E-post
                        </label>
                        <div className="relative">
                          {errors.email && (
                            <label className="font-sans text-xs text-orange-dark mt-1 absolute inset-y-0 right-0 p-2 pr-4 ">
                              Ugyldig e-post
                            </label>
                          )}
                          <input
                            type="Email"
                            ref={value => (inputRef.current["email"] = value)}
                            id="floatField"
                            className={
                              errors.email
                                ? inputFieldErrorModal
                                : inputFieldModal
                            }
                            placeholder="Fyll inn e-post"
                            defaultValue={userProfile.data?.email}
                            {...register("email", {
                              pattern: /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                          ></input>
                        </div>
                      </div>
                      {/* Phonenumber */}
                      <div>
                        <label className="text-sm text-blue font-sans">
                          Mobilnummer
                        </label>
                        <div className="relative">
                          <div
                            className={
                              errors.mobilePhone
                                ? inputFieldPhoneNumberError
                                : inputFieldPhoneNumber
                            }
                          >
                            <Controller
                              name="mobilePhone"
                              control={control}
                              defaultValue={userProfile?.data?.mobilePhone}
                              render={({
                                field: { onChange, value, name },
                              }) => (
                                <PhoneInput
                                  autoFormat={false}
                                  inputStyle={{
                                    maxHeight: "36px",
                                    width: "100%",
                                    border: "0px",
                                    borderRadius: "0px",
                                    boxShadow: "0px",
                                    color: "black",
                                    fontSize: "18px",
                                  }}
                                  country={"no"}
                                  inputProps={{
                                    name: name,
                                  }}
                                  onlyCountries={CountriesToValidate}
                                  value={phoneValue}
                                  onChange={e => setPhoneValue(e)}
                                  isValid={inputNumber =>
                                    validatePhoneMiniUser(
                                      inputNumber,
                                      setValidPhone
                                    )
                                  }
                                />
                              )}
                            />
                            {(errors.mobilePhone || validPhone === false) && (
                              <label className="font-sans text-orange-dark flex items-center absolute  inset-y-0 right-0 p-2 pr-4 ">
                                Ugyldig telefonnummer
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* Address */}
                      <div className="flex flex-col items-start space-y-2">
                        <span className="text-sm text-blue font-sans">
                          Adresse
                        </span>
                        <input
                          type="string"
                          className={inputFieldModal}
                          placeholder="Adresse"
                          defaultValue={userProfile.data?.addressLine1}
                          {...register("addressLine1")}
                        ></input>
                      </div>
                      {/* Postal code */}
                      <div>
                        <label className="text-sm text-blue font-sans">
                          Postnummer (valgfritt)
                        </label>
                        <div className="relative">
                          {errors.postalCode && (
                            <label className="font-sans text-xs text-orange-dark mt-1 absolute inset-y-0 right-0 p-2 pr-4 ">
                              Ugyldig postnummer
                            </label>
                          )}
                          <input
                            type="text"
                            inputMode="numeric"
                            maxLength={4}
                            className={
                              errors.postalCode
                                ? inputFieldErrorModal
                                : inputFieldModal
                            }
                            placeholder="Postnummer"
                            defaultValue={userProfile.data?.postalCode}
                            {...register("postalCode", {
                              maxLength: 4,
                              minLength: 4,
                            })}
                          ></input>
                        </div>
                      </div>
                    </div>
                    {/* Buttons */}
                    <div className="mt-5 mb-2 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button type="submit" className={saveButton + down}>
                        Lagre
                      </button>
                      <button
                        type="reset"
                        onClick={() => {
                          reset()
                          setPhoneValue(userProfile?.data?.mobilePhone)
                          queryClient.invalidateQueries(["profile"])
                        }}
                        className="border-b border-blue font-sans text-blue mb-4"
                      >
                        Forkast endringer
                      </button>
                    </div>
                    <div>
                      {/* Validation errors */}
                      {(errors.lastName ||
                        errors.email ||
                        errors.mobilePhone) && (
                        <label className="font-sans text-orange-dark">
                          Du har glemt å fylle noen felter
                        </label>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
