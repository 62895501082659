import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { GetMembership, GetProfile } from "../Utils/ReactQueries"
import { authToken } from "../Security/authToken"
import { SearchableDropDown, SimpleDropDown } from "../Styling/DropwDowns"
import { navigate, useStaticQuery } from "gatsby"
import { patchApi } from "../Utils/WebApi-utils"
import moment from "moment"
import {
  down,
  right,
  saveButton,
  showMoreButton,
  underlineButton,
} from "../Styling/Buttons"
import LoadingModal from "../Modal/LoadingModal"
import { BranchController } from "./BranchController"
import { CheckAge } from "../Common/Methods"

export const MySubscriptions = () => {
  const [seeSub, setSeeSub] = useState(false)
  const [seeIncomingSub, setSeeIncomingSub] = useState(false)
  const [seeBranch, setSeeBranch] = useState("")
  const [org, setOrg] = useState("")
  const { instance, accounts, inProgress } = useMsal()
  const { register, handleSubmit } = useForm()
  const [editMode, setEditMode] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [hefAge, setHefAge] = useState(false)
  const [value, setValue] = useState()
  const [branchId, setBranchId] = useState(
    "2af32023-b167-eb11-b0b6-000d3a201451"
  )
  const [patchObject, setPatchObject] = useState({
    id: "",
    branchidValue: "",
  })
  const queryClient = useQueryClient()
  const OrgMap = new Map([
    ["778380000", "HEF"],
    ["778380001", "HU"],
  ])
  const Branches = [
    { name: "Akershus", value: 1 },
    { name: "Asker", value: 2 },
  ]
  const localOrg = ["778380000"]

  const userProfile = GetProfile(accounts, inProgress, instance)

  const GetSubscriptions = (account, inProgress, instance) =>
    useQuery(
      "getSubscription",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSubscription/MySubscriptions",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeSub,
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  const GetIncomingSubscriptions = (account, inProgress, instance) =>
    useQuery(
      "getIncomingSubscription",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSalesOrder/CheckSubscription",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        enabled: seeSub,
        onSuccess: data => {
          data?.length > 0 ? setSeeIncomingSub(true) : setSeeIncomingSub(false)
        },
        retry: (count, error) => {
          if (count >= 3) {
            setLoadingError(true)
            return false
          }
          return true
        },
      }
    )

  // const GetBranch = async (account, inProgress, instance) => {
  //   //await new Promise(resolve => setTimeout(resolve, 500))
  //   return axios
  //     .get(
  //       process.env.GATSBY_APP_WEBAPI + "HefBranch/BranchHierarchy?parrentAccount=" + branchId,
  //       {
  //         headers: {
  //           "Conent-Type": "application/json",
  //           "Ocp-Apim-Subscription-Key": process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
  //           Authorization:
  //             "Bearer " + (await authToken(account, inProgress, instance)),
  //         },
  //       }
  //     )
  //     .then(res => res.data)}

  const UpdateSubscription = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "Abonnement/MySubscription",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        queryClient.invalidateQueries(["getSubscription"])
      },
    }
  )

  const MySubs = GetSubscriptions(accounts, inProgress, instance)

  const MyIncomingSubs = GetIncomingSubscriptions(
    accounts,
    inProgress,
    instance
  )
  const membership = GetMembership(accounts, inProgress, instance)

  console.log(MyIncomingSubs)
  useEffect(() => {
    setHefAge(false)
    //If it is HU membership, we need to check the age
    if (userProfile.isSuccess && membership.isSuccess) {
      CheckAge(userProfile?.data?.socialSecurityNumber, setHefAge)
    }
  }, [membership.isSuccess, userProfile.isSuccess])

  useEffect(() => {
    if (seeSub != false) {
      setSeeSub(true)
    }
  }, [seeSub])

  let patchObj = {
    id: "",
    branchidValue: "",
  }

  let branchMap = new Map([
    ["778380000", "hef"],
    ["778380001", "hu"],
  ])

  const editBranch = (orgValue, subNumber) => {
    console.log(orgValue)
    console.log(subNumber)
    setOrg(branchMap.get(orgValue))
    setSeeBranch(subNumber)
    setEditMode(!editMode)
    // console.log(subNumber)
  }

  const onBeMemberClickHef = () => {
    if (
      accounts[0]?.idTokenClaims["idp"] === "BankId" ||
      accounts[0]?.idTokenClaims["idp"] === "Vipps"
    ) {
      navigate("/hefinn")
    } else {
      navigate("/verifisering")
    }
  }

  const onBeMemberClickHu = () => {
    if (
      accounts[0]?.idTokenClaims["idp"] === "BankId" ||
      accounts[0]?.idTokenClaims["idp"] === "Vipps"
    ) {
      navigate("/huinn")
    } else {
      navigate("/verifisering")
    }
  }

  // console.log(MySubs?.data)
  // console.log(MyIncomingSubs?.data)

  return (
    <div>
      {MySubs.isLoading || MyIncomingSubs.isLoading ? (
        <LoadingModal />
      ) : (
        <div>
          <div
            className={
              showMoreButton +
              "flex justify-between border-blue items-center py-3"
            }
            onClick={() => {
              setSeeSub(!seeSub)
              setEditMode(false)
            }}
          >
            {seeSub ? (
              <span className="text-base font-sans font-semibold">
                Medlemskap
              </span>
            ) : (
              <span className="text-base font-sans">Medlemskap</span>
            )}

            {seeSub ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-xl font-bold font-serif transition-all"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            )}
          </div>
          <div>
            {seeSub ? (
              <div>
                {loadingError ? (
                  <div>
                    <p className="font-sans">
                      Det har skjedd en feil med dine medlemskap, prøv igjen
                      senere
                    </p>
                  </div>
                ) : (
                  <div className="pb-6">
                    <div className="font-sans text-blue mb-2">
                      <label>Medlemskap i Human-Etisk Forbund</label>
                    </div>
                    {MySubs?.data?.length > 0 ? (
                      <div>
                        {MySubs?.data.map((item, i) => (
                          <div>
                            {item?.organization == 778380000 ? (
                              <div key={i} className="mb-4">
                                <div
                                  className="flex flex-col space-y-2"
                                  key={i}
                                >
                                  {/* <label className="font-sans text-blue">
                                    {item?.productId?.name}
                                  </label> */}
                                  <label className="font-sans">
                                    Medlem siden{" "}
                                    <label className="font-bold">
                                      {moment(item?.startDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </label>
                                  </label>
                                  <label className="font-sans">
                                    {item?.branchId?.name}
                                  </label>
                                </div>
                                {seeBranch == item.subscriptionNumber &&
                                editMode ? (
                                  <BranchController
                                    org={org}
                                    subNumber={item.subscriptionNumber}
                                    setEditMode={setEditMode}
                                  />
                                ) : null}
                                {seeBranch == item.subscriptionNumber &&
                                editMode ? (
                                  <button
                                    onClick={() =>
                                      editBranch(
                                        item.organization,
                                        item.subscriptionNumber
                                      )
                                    }
                                    className={underlineButton + " mt-2 "}
                                  >
                                    Lukk
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      editBranch(
                                        item.organization,
                                        item.subscriptionNumber
                                      )
                                    }
                                    className={underlineButton + " mt-2 "}
                                  >
                                    Bytt lokallag
                                  </button>
                                )}
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {seeIncomingSub ? (
                          <div>
                            {MyIncomingSubs?.data.map((item, i) => (
                              <div>
                                {item?.productNumber == "HEF" ? (
                                  <label className="font-sans">
                                    Ditt medlemskap hos HEF blir nå aktivert
                                  </label>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div>
                            <div className="flex flex-col">
                              <label className="font-sans">
                                Du er{" "}
                                <label className="font-sans font-bold">
                                  ikke{" "}
                                </label>
                                medlem
                              </label>
                            </div>
                            <button
                              onClick={onBeMemberClickHef}
                              className={saveButton + right + " mt-2"}
                            >
                              Bli medlem i HEF
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div>
            {seeSub ? (
              <div>
                {loadingError ? (
                  <div>
                    <p className="font-sans">
                      Det har skjedd en feil med dine medlemskap, prøv igjen
                      senere
                    </p>
                  </div>
                ) : (
                  <div className="pb-6">
                    {MySubs?.data?.length > 0 ? (
                      <div>
                        {hefAge == false &&
                        (MySubs?.data.length == 0 ||
                          MySubs?.data.length == 1) ? (
                          <div>
                            <div className="font-sans text-blue mb-2">
                              <label>Medlemskap i Humanistisk Ungdom</label>
                            </div>
                            <div className="flex flex-col">
                              <label className="font-sans">
                                Du er{" "}
                                <label className="font-sans font-bold">
                                  ikke{" "}
                                </label>
                                medlem
                              </label>
                            </div>
                            <button
                              onClick={onBeMemberClickHu}
                              className={saveButton + right + " mt-2"}
                            >
                              Bli medlem i HU
                            </button>
                          </div>
                        ) : null}
                        {MySubs?.data.map((item, i) => (
                          <div>
                            {item?.organization == 778380001 ? (
                              <div key={i} className="mb-4">
                                <div
                                  className="flex flex-col pt-2 space-y-2"
                                  key={i}
                                >
                                  <label className="font-sans text-blue">
                                    Medlemskap i Humanistisk Ungdom
                                  </label>
                                  {/* <label className="font-sans text-blue">
                                    {item?.productId?.name}
                                  </label> */}
                                  <label className="font-sans">
                                    Medlem siden{" "}
                                    <label className="font-bold">
                                      {moment(item?.startDate).format(
                                        "DD.MM.YYYY"
                                      )}
                                    </label>
                                  </label>
                                  <label className="font-sans">
                                    {item?.branchId?.name}
                                  </label>
                                </div>
                                {seeBranch == item.subscriptionNumber &&
                                editMode ? (
                                  <BranchController
                                    org={org}
                                    subNumber={item.subscriptionNumber}
                                    setEditMode={setEditMode}
                                  />
                                ) : null}
                                {seeBranch == item.subscriptionNumber &&
                                editMode ? (
                                  <button
                                    onClick={() =>
                                      editBranch(
                                        item.organization,
                                        item.subscriptionNumber
                                      )
                                    }
                                    className={underlineButton + " mt-2 "}
                                  >
                                    Lukk
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      editBranch(
                                        item.organization,
                                        item.subscriptionNumber
                                      )
                                    }
                                    className={underlineButton + " mt-2 "}
                                  >
                                    Bytt lokallag
                                  </button>
                                )}
                              </div>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>
                        {seeIncomingSub ? (
                          <div>
                            {MyIncomingSubs?.data.map((item, i) => (
                              <div>
                                {item?.productNumber == "HU" ? (
                                  <label className="font-sans">
                                    Ditt medlemskap hos HU blir nå aktivert
                                  </label>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        ) : seeIncomingSub == false && hefAge == false ? (
                          <div>
                            <div className="font-sans text-blue mb-2">
                              <label>Medlemskap i Humanistisk Ungdom</label>
                            </div>
                            <div className="flex flex-col">
                              <label className="font-sans">
                                Du er{" "}
                                <label className="font-sans font-bold">
                                  ikke{" "}
                                </label>
                                medlem
                              </label>
                            </div>
                            <button
                              onClick={onBeMemberClickHu}
                              className={saveButton + right + " mt-2"}
                            >
                              Bli medlem i HU
                            </button>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {seeSub ? (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
          ) : (
            <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
          )}
        </div>
      )}
    </div>
  )
}
