import { useMsal } from "@azure/msal-react"
import { blue } from "@mui/material/colors"
import axios from "axios"
import React, { Component, useEffect, useState, useMemo } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-toastify"
import LoadingModal from "../Modal/LoadingModal"
import { authToken } from "../Security/authToken"
import { showMoreButton } from "../Styling/Buttons"
import { GetProfile } from "../Utils/ReactQueries"
import { patchApi } from "../Utils/WebApi-utils"
import { GetSubscriptions } from "../Utils/ReactQueries"
import { setConfig } from "react-hot-loader"
import { string } from "prop-types"
import internal from "stream"
import { type } from "os"
import { navigate } from "gatsby"

setConfig({ pureSFC: true })
type Branch = {
  name: string
  id: string
  type: number
}
export const BranchController = ({ org, subNumber, setEditMode }) => {
  const [childBranches, setChildBranches] = useState([])
  const { instance, accounts, inProgress } = useMsal()
  const [seeChildren, setSeeChildren] = useState(false)
  const [branchNameType, setBranchNameType] = useState("")
  const [branchName, setBranchName] = useState("")
  const [loading, setLoading] = useState(false)
  const [branchError, setBranchError] = useState(false)
  const [parrentBranch, setParrentBranch] = useState<Branch>({
    id: "",
    name: "",
    type: 0,
  })
  let BranchTypeMap = new Map([
    [778380004, ""],
    [778380000, ""],
    [778380001, ""],
    [778380002, ""],
    [778380003, ""],
  ])

  // const [branchInfo, setBranchInfo] = useState([
  //   {
  //     key: 778380004,
  //     value: "2af32023-b167-eb11-b0b6-000d3a201451",
  //     name: "Human-Etisk Forbund",
  //   },
  //   { key: 778380000, value: "", name: "" },
  //   { key: 778380001, value: "", name: "" },
  //   { key: 778380002, value: "", name: "" },
  //   { key: 778380003, value: "", name: "" },
  // ])
  // const [branchInfo, setBranchInfo] = useState({
  //   778380004: {
  //     value: "2af32023-b167-eb11-b0b6-000d3a201451",
  //     name: "Human-Etisk Forbund",
  //   },
  //   778380000: { value: "", name: "" },
  //   778380001: { value: "", name: "" },
  //   778380002: { value: "", name: "" },
  //   778380003: { value: "", name: "" },
  // })

  const [parrent, setParrent] = useState(BranchTypeMap)
  let NameOfBranch = new Map([
    [778380000, ""],
    [778380001, ""],
    [778380002, ""],
    [778380003, ""],
  ])

  const [branchNames, setBranchNames] = useState(NameOfBranch)
  const queryClient = useQueryClient()

  useEffect(() => {
    console.log(branchNames)
  }, [branchNames])

  let BranchNameMap = new Map([
    [778380000, "Regioner"],
    [778380001, "Fylker"],
    [778380002, "Lokallag"],
    [778380003, "Distriktslag"],
  ])

  const GetBranchUpdate = async (branchId, name, type) => {
    //await new Promise(resolve => setTimeout(resolve, 500))
    setLoading(true)
    return axios
      .get(
        process.env.GATSBY_APP_WEBAPI +
          "HefBranch/BranchHierarchy?parrentAccount=" +
          branchId,
        {
          headers: {
            "Conent-Type": "application/json",
            "Ocp-Apim-Subscription-Key":
              process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
            Authorization:
              "Bearer " + (await authToken(accounts, inProgress, instance)),
          },
        }
      )
      .then(res => {
        setSeeChildren(true)
        setChildBranches(res.data)
        setBranchName(name)
        setBranchNameType(BranchNameMap.get(type))
        setBranchNames(prev => prev.set(type, name))
        //setParrent(parrent.set(type, branchId))
        setParrent(prevState => prevState.set(type, branchId))

        setParrentBranch({
          name: name,
          id: branchId,
          type: type,
        })

        setLoading(false)
        console.log(parrent)
        console.log(branchNames)
      })
      .catch(error => setBranchError(true))
  }

  return (
    <div className="mt-2">
      {}
      {/* Change region */}
      {seeChildren ? (
        <div>
          {loading ? (
            <LoadingModal />
          ) : (
            <ChildAccounts
              getBranchUpdate={GetBranchUpdate}
              childBranches={childBranches}
              subNumber={subNumber}
              branchName={branchName}
              branchNameType={branchNameType}
              parrentBranch={parrentBranch}
              parrent={parrent}
              branchNames={branchNames}
              setSeeChildren={setSeeChildren}
              setEditMode={setEditMode}
            />
          )}
        </div>
      ) : (
        <div>
          {branchError ? (
            <div>
              <p className="font-sans">
                Det har skjedd en feil under endringen av lokallag, prøv igjen
                senere
              </p>
            </div>
          ) : (
            <RegionBranch
              org={org}
              getBranchUpdate={GetBranchUpdate}
              branchNameType={branchNameType}
            />
          )}
        </div>
      )}
    </div>
  )
}

// Region
const RegionBranch = ({ org, getBranchUpdate, branchNameType }) => {
  const { instance, accounts, inProgress } = useMsal()
  const [branchError, setBranchError] = useState(false)

  const GetBranch = (account, inProgress, instance) =>
    useQuery(
      "getBranches",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI +
              "HefBranch/Branches?organization=" +
              org +
              "&branchType=region",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => {
            return res.data
          })
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            setBranchError(true)
            return false
          }
          return true
        },
      }
    )

  const MyBranches = GetBranch(accounts, inProgress, instance)

  return (
    <div className="bg-white font-sans text-blue px-4 py-4">
      <div className="border-b-2 border-blue pb-2">
        <label className="font-bold ">Bytt lokallag </label>
      </div>
      {MyBranches.isLoading ? (
        <LoadingModal />
      ) : (
        <div>
          {branchError ? (
            <div>
              <p>Det har skjedd en feil på lokallagene, prøv igjen senere</p>
            </div>
          ) : (
            <div>
              {MyBranches.data?.map((item, i) => (
                <div key={i}>
                  <label
                    onClick={() =>
                      getBranchUpdate(item.branchId, item.name, item.branchType)
                    }
                    className={showMoreButton + " border-b-2 border-blue "}
                  >
                    {item.name}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

//ParentAccounts

// ChildAccounts
const ChildAccounts = ({
  getBranchUpdate,
  childBranches,
  subNumber,
  branchName,
  branchNameType,
  parrentBranch,
  parrent,
  branchNames,
  setSeeChildren,
  setEditMode,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const [startUseEffect, setStartUseEffect] = useState(false)

  const PatchBranch = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefSubscription/MySubscription",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        toast.success("Lokallaget ble endret")
        queryClient.invalidateQueries(["getSubscription"])
      },
      onError: data => {
        toast.error("Noe gikk galt med endringen!")
      },
    }
  )
  const onClickBranch = (branchId, hasChildren, branchType, name) => {
    if (
      (hasChildren || hasChildren == undefined) &&
      branchType >= 778380001 &&
      branchType < 778380003
    ) {
      console.log("step1")
      getBranchUpdate(branchId, name, branchType)
    } else if (hasChildren == false || branchType === 778380003) {
      let patchObject: any = {
        SubscriptionNumber: subNumber,
        BranchidValue: branchId,
        OverridenBranch: true,
      }
      console.log(patchObject)
      PatchBranch.mutate(patchObject)
      setEditMode(false)
    }

    setStartUseEffect(!startUseEffect)
  }

  console.log(parrentBranch.type)
  console.log(parrentBranch.id)
  console.log(branchName)
  console.log(branchNameType)

  return (
    <div className="bg-white font-sans text-blue px-4 py-4">
      <div className="border-b-2 border-blue pb-2">
        <label className="font-bold ">Bytt lokallag</label>
      </div>
      {/* Go back in menu */}
      <label
        onClick={() => {
          if (parrentBranch.type == 778380000) {
            setSeeChildren(false)
          } else {
            getBranchUpdate(
              parrent.get(parrentBranch.type - 1),
              branchNames.get(parrentBranch.type - 1),
              parrentBranch.type - 1
            )
          }
        }}
        className={showMoreButton + " border-b-2 border-blue "}
      >
        <div className="flex flex-row">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            />
          </svg>
          {branchNameType}
        </div>
        <div>
          <label className="font-bold">{branchName}</label>
        </div>
      </label>
      {/* Go forward in the menu*/}
      <div>
        {parrentBranch.type == 778380002 ? (
          <div>
            <label
              onClick={() => {
                onClickBranch(
                  parrentBranch.id,
                  false,
                  778380002,
                  parrentBranch.name
                )
              }}
              className="text-blue hover:cursor-pointer hover:text-orange-dark border-b-2 border-blue items-center pt-3 pb-4 group peer transition-all"
            >
              <div className="flex flex-row pt-3 pb-4 border-b-2 border-blue justify-between">
                {parrentBranch.name}
              </div>
            </label>
          </div>
        ) : null}
      </div>
      {childBranches.map((item, i) => (
        <div key={i}>
          <label
            onClick={() => {
              onClickBranch(
                item.branchId,
                item?.hasChildren,
                item?.branchType,
                item?.name
              )
            }}
            className="text-blue hover:cursor-pointer hover:text-orange-dark border-b-2 border-blue items-center pt-3 pb-4 group peer transition-all"
          >
            {item?.hasChildren ||
            item.branchType === 778380001 ||
            item.branchType === 778380000 ? (
              <div className="flex flex-row pt-3 pb-4 border-b-2 border-blue justify-between">
                {item.name}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            ) : (
              <div className="flex flex-row pt-3 pb-4 border-b-2 border-blue justify-between">
                {item.name}
              </div>
            )}
          </label>
        </div>
      ))}
    </div>
  )
}
