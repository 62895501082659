import * as React from "react"

type CheckmarkType = {
  className?: string
  strokeWidth?: number
  color?: string
}

export const Checkmark = (props: CheckmarkType) => {
  const className = props.className || "h-4 w-4"
  const strokeWidth = props.strokeWidth || 2
  const color = props.color || "white"

  return (
    /*
    <svg className={className} stroke={color} viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path strokeWidth={strokeWidth} d="M9.20692 0.793031C9.39439 0.980558 9.49971 1.23487 9.49971 1.50003C9.49971 1.76519 9.39439 2.0195 9.20692 2.20703L4.20692 7.20703C4.01939 7.3945 3.76508 7.49982 3.49992 7.49982C3.23475 7.49982 2.98045 7.3945 2.79292 7.20703L0.792919 5.20703C0.610761 5.01843 0.509966 4.76583 0.512245 4.50363C0.514523 4.24143 0.619692 3.99062 0.8051 3.80521C0.990508 3.6198 1.24132 3.51463 1.50352 3.51236C1.76571 3.51008 2.01832 3.61087 2.20692 3.79303L3.49992 5.08603L7.79292 0.793031C7.98045 0.60556 8.23475 0.500244 8.49992 0.500244C8.76508 0.500244 9.01939 0.60556 9.20692 0.793031Z" fill="white"/>
    </svg>*/
    <label className="font-sans text-white">V</label>
  )
}
