import React, { Component, useEffect, useRef } from "react"
import { useState } from "react"
import { patchApi } from "../Utils/WebApi-utils"
import { GetProfile } from "../Utils/ReactQueries"
import { GenderDropDown, SimpleDropDown } from "./DropwDowns"
import { useMsal } from "@azure/msal-react"
import { useMutation, useQueryClient } from "react-query"
import axios from "axios"
import { authToken } from "../Security/authToken"
import { Controller, useForm } from "react-hook-form"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { underlineButton, down, saveButton, showMoreButton } from "./Buttons"
import {
  inputField,
  inputFieldError,
  inputFieldPhoneNumber,
  inputFieldPhoneNumberError,
} from "./InputField"
import { EventMessageUtils } from "@azure/msal-browser"
import "./styles.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { CountriesToValidate } from "../Utils/CountriesToValidate"
import validator from "validator"
import { validatePhoneMiniUser } from "../Utils/Utils"
import LoadingModal from "../Modal/LoadingModal"

/**
 * This component is for updating personal information about the user.
 *
 * @version 1.0.1
 * @visibleName Personal information
 * @author [Dylan Lesperance]
 * @author [Henrik Lie]
 */

export const UpdateProfile = ({}) => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeProfile, setSeeProfile] = useState(false)
  const inputRef = useRef({})
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [genderCode, setGenderCode] = useState(userProfile.data?.genderCode)
  const [validPhone, setValidPhone] = useState(false)

  const [phoneValue, setPhoneValue] = useState(userProfile?.data?.mobilePhone)
  const queryClient = useQueryClient()
  const [token, setToken] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })
  const [savePersonalInfo, setSavePersonalInfo] = useState(true)
  const [checkRequired, setCheckRequired] = useState(true)
  const [mobileDefaultValue, setMobileDefaultValue] = useState(null)

  const tzOffset = new Date().getTimezoneOffset() * 60000
  const localTime = new Date(Date.now() - tzOffset).toISOString()
  const today = localTime

  const patchObj = {
    id: userProfile.data?.id,
    fullName: userProfile.data?.fullName,
    firstName: userProfile.data?.firstName,
    lastName: userProfile.data?.lastName,
    middleName: userProfile.data?.middleName,
    emailAddress: userProfile.data?.email,
    mobilephone: userProfile.data?.mobilePhone,
    address: userProfile.data?.addressLine1,
    genderCode: genderCode,
    pronoun: userProfile.data?.pronoun,
    birthDate: userProfile.data?.birthDate,
    test: userProfile.data?.philosophicalSociety,
  }
  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => {
        res.data
      }),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        toast.success("Endringene ble lagret")
        queryClient.invalidateQueries(["profile"])
        // console.log(today)
      },
      onError: data => {
        toast.error("Noe gikk galt!")
      },
    }
  )

  const onSubmit = values => {
    values.mobilePhone = phoneValue
    console.log(values)

    if (
      (values.mobilePhone == undefined ||
        values.mobilePhone == "" ||
        values.mobilePhone.length <= 3) &&
      (values.email == undefined || values.email == "")
    ) {
      setValidPhone(false)
      // errors.forEach(({ name, type, message }) =>
      //   setError(name, { type, message })
      // )
      setError("email", {
        message: "Ugyldig nummer",
      })
      setError("mobilePhone", {
        message: "Ugyldig nummer",
      })

      return toast.error("Skriv inn mobilnummer eller e-post")
    }
    if (validPhone === false) {
      return toast.error("Mobilnummer er ugyldig")
    }

    values.genderCode = parseInt(values.genderCode)

    if (values.mobilePhone == undefined || values.mobilePhone.length < 3) {
      values.mobilePhone = ""
    } else if (values.mobilePhone.length == 11) {
      values.mobilePhone = values.mobilePhone
    } else {
      values.mobilePhone = "+" + values.mobilePhone
    }
    if (values.mobilePhone) {
    }
    values.modifiedByCustomer = today
    UpdateUser.mutate(values)

    console.log(userProfile.data?.mobilePhone)
  }

  const openUserInfo = async () => {
    setSeeProfile(!seeProfile)
    await userProfile.refetch()
    reset()
  }

  const Gender = [
    { name: "Mann", value: 1 },
    { name: "Kvinne", value: 2 },
    { name: "Kjønnskeiv", value: 778380001 },
    { name: "Ikke kjønnet", value: 778380000 },
    { name: "Ikke binær", value: 778380002 },
  ]
  const PhilosophicalSociety = [
    { name: "Ja", value: "778380000" },
    { name: "Uavklart", value: "778380002" },
    { name: "Nei", value: "778380001" },
  ]

  useEffect(() => {
    if (
      userProfile.data?.email?.length == 0 &&
      userProfile.data?.mobilePhone?.length == 0
    ) {
      setCheckRequired(true)
    } else if (validPhone || userProfile?.data?.email?.length > 0) {
      setCheckRequired(false)
    }
  }, [inputRef.current["email"]])

  useEffect(() => {
    if (
      userProfile.data?.email?.length == 0 &&
      userProfile.data?.mobilePhone?.length == 0
    ) {
      setCheckRequired(true)
    }
  }, [inputRef.current["email"]])

  // useEffect(() => {
  //   if (userProfile.isSuccess) {
  //     setPhoneValue(userProfile?.data?.mobilePhone)
  //   }
  // }, [userProfile.isSuccess])

  useEffect(() => {
    console.log(mobileDefaultValue)
  }, [mobileDefaultValue])

  useEffect(() => {
    if (
      mobileDefaultValue == null &&
      userProfile?.data?.mobilePhone != undefined
    ) {
      setMobileDefaultValue(userProfile?.data?.mobilePhone)
      setPhoneValue(userProfile?.data?.mobilePhone)
    }
  }, [userProfile?.data?.mobilePhone])

  return (
    <div className="relative">
      <div
        className={showMoreButton + "border-t-2 border-blue"}
        onClick={openUserInfo}
      >
        {seeProfile ? (
          <span className="text-base font-sans font-semibold">
            Personopplysninger
          </span>
        ) : (
          <span className="text-base font-sans">Personopplysninger</span>
        )}
        {seeProfile ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        )}
      </div>
      {/* Profile */}
      {seeProfile ? (
        <div className=" transition-all ">
          <form name="contact" method="patch" onSubmit={handleSubmit(onSubmit)}>
            {UpdateUser.isLoading ? (
              <LoadingModal />
            ) : (
              <div className="flex flex-col space-y-7 justify-between border-blue">
                <div>
                  {userProfile?.data?.socialSecurityNumber ==
                  undefined ? null : (
                    <span className="font-sans text-base">
                      Ditt fødselsnummer er{" "}
                      {userProfile?.data?.socialSecurityNumber?.substring(
                        0,
                        5
                      ) +
                        "*****" +
                        userProfile?.data?.socialSecurityNumber?.substring(
                          10
                        )}{" "}
                    </span>
                  )}
                </div>
                {/* First name */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Fornavn
                  </label>
                  <div
                    className="space-y-3 relative"
                    key={userProfile.data?.firstName}
                  >
                    <input
                      type="string"
                      className={inputField}
                      placeholder="Fornavn"
                      defaultValue={userProfile.data?.firstName}
                      {...register("firstName")}
                      //onChange={e => (patchObj.firstName = e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Last name */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Etternavn
                  </label>
                  <div>
                    <input
                      type="string"
                      className={errors.lastName ? inputFieldError : inputField}
                      placeholder="Etternavn"
                      defaultValue={userProfile.data?.lastName}
                      {...register("lastName", { required: true })}
                    ></input>
                  </div>
                </div>
                {/* Email */}
                <div>
                  <label className="text-base text-blue font-sans">
                    E-post
                  </label>
                  <div className="relative">
                    {errors.email && (
                      <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 invisible sm:visible">
                        Ugyldig e-post
                      </label>
                    )}
                    <input
                      type="Email"
                      ref={value => (inputRef.current["email"] = value)}
                      id="floatField"
                      className={errors.email ? inputFieldError : inputField}
                      placeholder="E-post"
                      defaultValue={userProfile.data?.email}
                      {...register("email", {
                        pattern: /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    ></input>
                  </div>
                  {errors.email && (
                    <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                      Ugyldig e-post
                    </label>
                  )}
                </div>
                {/* Phonenumber */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Mobilnummer
                  </label>
                  <div className="relative">
                    <div
                      className={
                        errors.mobilePhone
                          ? inputFieldPhoneNumberError
                          : inputFieldPhoneNumber
                      }
                    >
                      <Controller
                        name="mobilePhone"
                        control={control}
                        defaultValue={mobileDefaultValue}
                        render={({ field: { onChange, value, name } }) => (
                          <PhoneInput
                            autoFormat={false}
                            // containerStyle={{
                            //   backgroundColor: "black !important",
                            //   border: "10px !important",
                            // }}
                            inputStyle={{
                              maxHeight: "52px",
                              width: "100%",
                              border: "0px",
                              borderRadius: "0px",
                              boxShadow: "0px",
                              color: "black",
                              fontSize: "18px",
                            }}
                            country={"no"}
                            inputProps={{
                              name: name,
                            }}
                            onlyCountries={CountriesToValidate}
                            value={phoneValue}
                            onChange={e => setPhoneValue(e)}
                            isValid={inputNumber =>
                              validatePhoneMiniUser(inputNumber, setValidPhone)
                            }
                          />
                        )}
                      />
                      {(errors.mobilePhone || validPhone === false) && (
                        <label className="font-sans text-orange-dark flex items-center absolute  inset-y-0 right-0 p-2 pr-4 invisible sm:visible">
                          Ugyldig telefonnummer
                        </label>
                      )}
                    </div>
                    {(errors.mobilePhone || validPhone === false) && (
                      <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                        Ugyldig telefonnummer
                      </label>
                    )}
                  </div>
                </div>
                {/* Address */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Adresse (valgfritt)
                  </label>
                  <div>
                    <input
                      type="string"
                      className={inputField}
                      placeholder="Adresse"
                      defaultValue={userProfile.data?.addressLine1}
                      {...register("addressLine1")}
                    ></input>
                  </div>
                </div>
                {/* Postal code */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Postnummer (valgfritt)
                  </label>
                  <div className="relative">
                    {errors.postalCode && (
                      <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 invisible sm:visible ">
                        Ugyldig postnummer
                      </label>
                    )}
                    <input
                      type="text"
                      inputMode="numeric"
                      maxLength={4}
                      className={
                        errors.postalCode ? inputFieldError : inputField
                      }
                      placeholder="Postnummer"
                      defaultValue={userProfile.data?.postalCode}
                      {...register("postalCode", {
                        maxLength: 4,
                        minLength: 4,
                      })}
                    ></input>
                  </div>
                  {errors.postalCode && (
                    <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                      Ugyldig postnummer
                    </label>
                  )}
                </div>
                {/* Gender */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Kjønn (valgfritt)
                  </label>
                  <div className="relative mt-2">
                    <select
                      {...register("genderCode")}
                      className="font-sans text-sm text-black border-b-2 bg-white border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none"
                    >
                      <option
                        key="0"
                        value=""
                        style={{ display: "none" }}
                        selected
                      >
                        Ikke valgt
                      </option>
                      {Gender.map(option => (
                        <option
                          key={option.value}
                          value={option.value}
                          selected={
                            userProfile.data?.genderCode === option.value
                              ? true
                              : false
                          }
                        >
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Pronoun */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Pronomen (valgfritt)
                  </label>
                  <div>
                    <input
                      type="string"
                      className={inputField}
                      placeholder="Pronomen"
                      defaultValue={userProfile.data?.pronoun}
                      {...register("pronoun")}
                      //onChange={e => {(patchObj.pronoun = e.target.value)}}
                    ></input>
                  </div>
                </div>
                <div>
                  {/* Buttons */}
                  <button type="submit" className={saveButton + down}>
                    Lagre endringer
                  </button>
                  <button
                    type="reset"
                    onClick={() => {
                      reset()
                      setPhoneValue(userProfile?.data?.mobilePhone)
                      queryClient.invalidateQueries(["profile"])
                    }}
                    className={underlineButton}
                  >
                    Forkast endringer
                  </button>
                </div>
                <div>
                  {/* Validation errors */}
                  {(errors.lastName || errors.email || errors.mobilePhone) && (
                    <label className="font-sans text-orange-dark mt-2">
                      Du har glemt å fylle inn noen felter
                    </label>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      ) : null}
      {/* Accordion borders */}
      {seeProfile ? (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
      ) : (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
      )}
    </div>
  )
}
