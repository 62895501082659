import React, { useState, useEffect, useMemo, useRef } from "react"
import { QueryClient, QueryClientProvider, useQuery } from "react-query"
import { Link, navigate } from "gatsby"
import { Gender, GetProfile } from "../Utils/ReactQueries"

import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"
// import { fetchApi, isNullOrUndefined, patchApi } from "../Common/WebApi-utils"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import { loginRequest, msalConfig } from "../Security/authConfig"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { Loading } from "../Utils/Loading"
import { authToken } from "../Security/authToken"
import { UpdateProfile } from "../Styling/UpdateProfileForm"
import { MyConsents } from "./MyConsents"
import { SearchableDropDown, SimpleDropDown } from "../Styling/DropwDowns"
import { ConfirmUserInfo } from "../Modal/ConfirmModal"
import moment, { now } from "moment"
import ConfirmConnection from "./MyConnections"
import { MySubscriptions } from "./MySubscriptions"
import MyMarketing from "./MyMarketing"
import MyPosts from "./MyPosts"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import HumanLayout from "../Styling/HumanLayout"
import { MyDocuments } from "./MyDocuments"
import MyActivities from "./MyActivities"
import MyComAndMarketing from "./MyCommunicationAndMarketing"
import { string } from "prop-types"
import { showMoreButton, underlineButton } from "../Styling/Buttons"
import LoadingModal from "../Modal/LoadingModal"
import Seo from "../../components/seo"
import axios from "axios"

const queryClient = new QueryClient()

const UserProfileContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [editMode, setEditMode] = useState(false)
  const [name, setName] = useState(null)
  const [consentEdit, setConsentEdit] = useState(false)
  const [infoConfirmed, setInfoConfirmed] = useState(false)
  const [readyToCheck, setReadyToCheck] = useState(false)
  const [tokenString, setTokenString] = useState("")
  const [welcomeMessage, setWelcomeMessage] = useState(false)
  const [seeIncomingSub, setSeeIncomingSub] = useState(false)
  const [loadingError, setLoadingError] = useState(false)
  const [seeSub, setSeeSub] = useState(false)

  const [tokenReady, setTokenReady] = useState(false)

  console.log(accounts)
  console.log(inProgress)
  console.log(instance)

  const myProfile = GetProfile(accounts, inProgress, instance)

  const dateNow = new Date()
  // console.log(dateNow)

  // const showWelcomeMessage = moment
  //   .utc(dateNow.setMinutes(dateNow.getMinutes() - 5))
  //   .toISOString()

  // const createdOn = myProfile?.data?.createdOn

  // useEffect(() => {
  //   if (myProfile.isSuccess) {
  //     if (createdOn > showWelcomeMessage) {
  //       setWelcomeMessage(true)
  //     } else {
  //       setWelcomeMessage(false)
  //     }
  //   }
  // }, [myProfile.isSuccess])

  // console.log(createdOn)
  // console.log(showWelcomeMessage)

  if (myProfile?.data?.modifiedByCustomer != undefined) {
  }
  const modified = myProfile?.data?.modifiedByCustomer

  const dateChecker = moment
    .utc(dateNow.setMonth(dateNow.getMonth() - 6))
    // .utc(dateNow.setMinutes(dateNow.getMinutes() + 60))
    .toISOString()

  console.log(myProfile)

  useEffect(() => {
    if (myProfile.isSuccess && myProfile?.data != null) {
      if (modified == undefined || modified < dateChecker) {
        // console.log(true)
        setInfoConfirmed(true)
      } else {
        // console.log(false)
        setInfoConfirmed(false)
      }
    }
  }, [myProfile.isSuccess])

  const GetIncomingSubscriptions = (account, inProgress, instance) =>
    useQuery(
      "getIncomingSubscription",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(
            process.env.GATSBY_APP_WEBAPI + "HefSalesOrder/CheckSubscription",
            {
              headers: {
                "Conent-Type": "application/json",
                "Ocp-Apim-Subscription-Key":
                  process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
                Authorization:
                  "Bearer " + (await authToken(account, inProgress, instance)),
              },
            }
          )
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        onSuccess: data => {
          data?.length > 0 ? setSeeIncomingSub(true) : setSeeIncomingSub(false)
        },
        retry: 1,
      }
    )

  const MyIncomingSubs = GetIncomingSubscriptions(
    accounts,
    inProgress,
    instance
  )

  console.log(MyIncomingSubs?.data?.length)

  return (
    <div>
      {myProfile.isLoading ? (
        <LoadingModal />
      ) : (
        <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
          <div className="lg:min-w-[50%] lg:max-w-[50%]">
            <Seo title="Minside" />
            {infoConfirmed ? (
              <ConfirmUserInfo setInfoConfirmed={setInfoConfirmed} />
            ) : null}
            <div className="mb-8 flex flex-col">
              {seeIncomingSub ? (
                <span className="font-serif text-blue mb-4 text-xl">
                  Hei, {myProfile?.data?.firstName}! <br /> Velkommen til
                  Human-Etisk Forbund
                </span>
              ) : (
                <span className="font-serif text-blue mb-4 text-xl">
                  Hei, {myProfile?.data?.firstName}! <br /> Dette er din side
                </span>
              )}
              <span className="font-sans text-base mb-2">
                Medlemsnummeret ditt er{" "}
                <span className="font-bold">
                  {myProfile?.data?.customerNumber}
                </span>
              </span>
              <span className="font-sans text-base">
                Vervekoden din er{" "}
                <span className="font-bold">
                  {myProfile?.data?.recruitCode}
                </span>
              </span>
              {/* <div className="mt-4">
                <a
                  href="#dineInnstillinger"
                  className={underlineButton + " text-base "}
                >
                  Dine innstillinger
                </a>
              </div> */}
            </div>
            <ToastContainer autoClose={10000} />
            <div>
              <MyActivities />
            </div>
            <div className="mt-12 flow-root">
              <div className="mb-4" id="dineInnstillinger">
                <span className="font-serif font-semibold text-lg text-blue">
                  Dine innstillinger
                </span>
              </div>
              <div>
                <div>
                  <div>
                    <UpdateProfile />
                    <MyComAndMarketing />
                    <MySubscriptions />
                    <MyPosts />
                    <MyDocuments />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const UserInfo = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <UserProfileContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default UserInfo
