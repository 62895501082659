import { useMsal } from "@azure/msal-react"
import axios from "axios"
import React, { Component, useEffect, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { authToken } from "../Security/authToken"
import { patchApi } from "../Utils/WebApi-utils"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useForm } from "react-hook-form"
import Checkbox from "../Styling/Checkbox"
import LoadingModal from "../Modal/LoadingModal"
import { navigate } from "gatsby"

const MyMarketing = ({
  addMemberList,
  setAddMemberList,
  removeMemberList,
  setRemoveMemberList,
  exisitingMarketingList,
  setExisitingMarketingList,
}) => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeMarketing, setSeeMarketing] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const queryClient = useQueryClient()
  const [myMarketingListReady, setMyMarketingListReady] = useState(false)
  const [marketingListReady, setMarketingListReady] = useState(false)
  const [loadingError, setLoadingError] = useState(false)

  const { register, handleSubmit, setValue, reset } = useForm()

  const GetMyMarketinglists = (account, inProgress, instance) =>
    useQuery(
      "getMyMarketinglist",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefSubscriptionLists/me", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            setMyMarketingListReady(true), setLoadingError(true)
            return false
          }
          return true
        },
        onSuccess: data => {
          let temp = data?.mySubscriptionsListItems.map(item => {
            return item.listId
          })
          setExisitingMarketingList(temp)
          // console.log("Suksess")
        },
        onError: () => (setMyMarketingListReady(true), setLoadingError(true)),
      }
    )
  const GetMarketinglists = (account, inProgress, instance) =>
    useQuery(
      "getMarketinglist",
      async () => {
        //await new Promise(resolve => setTimeout(resolve, 500))
        return axios
          .get(process.env.GATSBY_APP_WEBAPI + "HefSubscriptionLists/All", {
            headers: {
              "Conent-Type": "application/json",
              "Ocp-Apim-Subscription-Key":
                process.env.GATSBY_APP_APIM_SUBSCRIPTION_KEY,
              Authorization:
                "Bearer " + (await authToken(account, inProgress, instance)),
            },
          })
          .then(res => res.data)
      },
      {
        refetchOnWindowFocus: false,
        retry: (count, error) => {
          if (count >= 3) {
            setMarketingListReady(true), setLoadingError(true)
            return false
          }
          return true
        },
        onError: () => (setMarketingListReady(true), setLoadingError(true)),
      }
    )

  const MyMarketinglists = GetMyMarketinglists(accounts, inProgress, instance)
  const Marketinglists = GetMarketinglists(accounts, inProgress, instance)

  const checkAlreadySub = listId => {
    if (
      MyMarketinglists.data?.mySubscriptionsListItems === undefined ||
      MyMarketinglists.data?.mySubscriptionsListItems === null
    ) {
      setValue(listId, false)
      return false
    }

    const index = MyMarketinglists.data?.mySubscriptionsListItems?.findIndex(
      item => item.listId === listId
    )
    // if (index > -1)
    //   setExisitingMarketingList([...exisitingMarketingList, listId])
    if (index === -1) {
      setValue(listId, false)
      return false
    } else {
      setValue(listId, true)
      return true
    }
  }

  //console.log(MyMarketinglists)
  //console.log(Marketinglists)

  const AddToLists = (listId, value) => {
    const indexExisting = exisitingMarketingList?.findIndex(
      item => item === listId
    )
    const indexAdd = addMemberList?.findIndex(item => item === listId)
    const indexRemove = removeMemberList?.findIndex(item => item === listId)

    if (value) {
      if (indexExisting === -1 && indexAdd === -1) {
        setAddMemberList(addMemberList.concat(listId))
      } else if (indexRemove > -1) {
        removeMemberList.splice(indexRemove, 1)
      }
    }
    if (value === false) {
      if (indexExisting > -1 && indexRemove === -1) {
        setRemoveMemberList(removeMemberList.concat(listId))
      } else if (indexAdd > -1) {
        addMemberList.splice(indexAdd, 1)
      }
    }
  }

  useEffect(() => {
    if (MyMarketinglists.isSuccess) {
      console.log(exisitingMarketingList)
      setMyMarketingListReady(true)
    }
  }, [MyMarketinglists.isSuccess])

  useEffect(() => {
    if (Marketinglists.isSuccess) {
      setMarketingListReady(true)
    }
  }, [Marketinglists.isSuccess])

  // console.log(Marketinglists)
  // console.log(MyMarketinglists)

  return (
    <div>
      <div>
        {marketingListReady === false || myMarketingListReady === false ? (
          <LoadingModal />
        ) : (
          <div className="space-y-4 my-8">
            <div className="flex justify-between items-center">
              <span className="font-sans text-base text-blue ">
                Nyhetsbrev abonnementer
              </span>
            </div>
            <div>
              {loadingError ? (
                <div>
                  <p className="font-sans">
                    Det har skjedd en feil med nyhetsbrevene, prøv igjen senere.
                  </p>
                </div>
              ) : (
                <div>
                  {Marketinglists.data?.map((item, i) => (
                    <div key={i} className="flex flex-row mt-4">
                      <Checkbox
                        key={item.listId}
                        defaultChecked={checkAlreadySub(item.listId)}
                        onClick={value => AddToLists(item.listId, value)}
                      />
                      <span className="ml-2 self-center font-sans text-xs">
                        {item.listName}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default MyMarketing
