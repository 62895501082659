import { useMsal } from "@azure/msal-react"
import React, { useState } from "react"
import { GetProfile } from "../Utils/ReactQueries"
import { patchApi } from "../Utils/WebApi-utils"
import { useMutation, useQueryClient } from "react-query"
import { SimpleDropDown } from "../Styling/DropwDowns"
import { useForm } from "react-hook-form"
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { spawn } from "child_process"
import MyMarketing from "./MyMarketing"
import Checkbox from "../Styling/Checkbox"
import { Consents } from "./MyCommunicationAndMarketing"
import LoadingModal from "../Modal/LoadingModal"

export const UpdateConsent = ({ setConsents, prevConsents, consents }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const queryClient = useQueryClient()
  const { register, handleSubmit, setValue, reset } = useForm()
  const [lastConsents, setLastConsents] = useState<Consents>(prevConsents)

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => res.data),
    {
      onSuccess: (data, values) => {
        //queryClient.setQueryData(["profile"], data)
        toast.success("Endringene i samtykke ble lagret")
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Noe gikk galt med samtykke!")
      },
    }
  )

  const onSubmit = values => {
    if (prevConsents !== values) {
      console.log("haalo")
      UpdateUser.mutate(values)
    } else toast.warning("Ingen endringer ble gjort")

    // console.log(prevConsents)
    // console.log(values)
    // setEditMode(false)
  }

  const ConsentLevel = [
    { name: "Ingen", value: 587030000 },
    { name: "Samtykke", value: 587030001 },
    { name: "Transaksjon", value: 587030002 },
    { name: "Abonnementer", value: 587030003 },
    { name: "Marketing", value: 587030004 },
    { name: "Profilering", value: 587030005 },
  ]

  let ConsentLevelMap = new Map([
    [587030000, "Ingen"],
    [587030001, "Samtykke"],
    [587030002, "Transaksjon"],
    [587030003, "Abonnementer"],
    [587030004, "Marketing"],
    [587030005, "Profilering"],
  ])

  // console.log(userProfile.data?.doNotBulkeMail)

  const customReset = () => {
    setValue("doNotPhone", false)
  }

  return (
    <div>
      <div className="space-y-4 my-4">
        {UpdateUser.isLoading ? (
          <LoadingModal />
        ) : (
          <>
            <div className="flex justify-between">
              <span className="font-sans text-base text-blue ">
                Samtykke til kommunikasjon
              </span>
            </div>
            <div className="flex flex-row">
              <div>
                <Checkbox
                  defaultChecked={!lastConsents.doNotEmail}
                  onClick={e => {
                    setConsents({
                      ...consents,
                      doNotEmail: e === true ? false : true,
                    })
                  }}
                />
              </div>
              <span className="text-xs text-black ml-2 font-sans">E-post</span>
            </div>
            <div className="flex flex-row">
              <div>
                <Checkbox
                  defaultChecked={!userProfile.data?.doNotBulkPostalMail}
                  onClick={e => {
                    setConsents({
                      ...consents,
                      doNotBulkPostalMail: e === true ? false : true,
                    })
                  }}
                />
              </div>
              <span className="text-xs text-black ml-2 font-sans">Post</span>
            </div>
            <div className="flex flex-row">
              <div>
                <Checkbox
                  defaultChecked={!userProfile.data?.doNotSms}
                  onClick={e => {
                    setConsents({
                      ...consents,
                      doNotSms: e === true ? false : true,
                    })
                  }}
                />
              </div>
              <span className="text-xs text-black ml-2 font-sans">SMS</span>
            </div>
            <div className="flex flex-row">
              <div>
                <Checkbox
                  defaultChecked={!userProfile.data?.doNotPhone}
                  onClick={e => {
                    setConsents({
                      ...consents,
                      doNotPhone: e === true ? false : true,
                    })
                  }}
                />
              </div>
              <span className="text-xs text-black ml-2 font-sans">Telefon</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export const MyConsents = ({ setConsents }) => {
  const { instance, accounts, inProgress } = useMsal()
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [editMode, setEditMode] = useState(false)
  const [seeConsents, setSeeConsents] = useState(false)

  let ConsentLevel = new Map([
    [587030000, "Ingen"],
    [587030001, "Samtykke"],
    [587030002, "Transaksjon"],
    [587030003, "Abonnementer"],
    [587030004, "Marketing"],
    [587030005, "Profilering"],
  ])

  return (
    <div>
      <div className="flex justify-between border-t-2 border-blue items-center py-3">
        <span className="text-base text-blue font-sans font-semibold">
          Kommunikasjon og nyhetsbrev
        </span>
        <button
          className="text-xl font-bold text-blue font-serif justify-between"
          onClick={() => setSeeConsents(!seeConsents)}
        >
          {seeConsents ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
      </div>
      {seeConsents ? (
        <div>
          <UpdateConsent setConsents={setConsents} />
          <MyMarketing
            setAddMemberList={setAddMemberList}
            setRemoveMemberList={setRemoveMemberList}
            setExisitingMarketingList={setExisitingMarketingList}
            addMemberList={addMemberList}
            exisitingMarketingList={exisitingMarketingList}
            removeMemberList={removeMemberList}
          />
        </div>
      ) : null}
    </div>
  )
}
